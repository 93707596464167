import ActionDropdown from "../../ActionDropdown";
import DateFormatter from "../../DateFormatter";
import ImageAndName from "../../ImageAndName";
import RenderUserStatus from "../../RenderUserStatus";
import TableCheck from "../TableCheck";

const SellersColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Name',
        Component: ImageAndName
    },
    {
        Label: () => 'Email',
        accessor: 'email'
    },
    {
        Label: () => 'Phone Number',
        accessor: 'whatsapp'
    },
    {
        Label: () => 'Estatus',
        Component: RenderUserStatus
    },
    {
        Label: () => 'Created At',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} dateFormat="dd/MM/yyyy" />
    },
    {
        Label: () => 'Actions',
        Component: ActionDropdown
    }
];

export default SellersColumns;

