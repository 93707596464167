import ActionDropdown from "../../ActionDropdown";
import DateFormatter from "../../DateFormatter";
import ImageAndName from "../../ImageAndName";
import TableCheck from "../TableCheck";

const PlansColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Name',
        Component: ImageAndName
    },
    {
        Label: () => 'Categories',
        Component: ({ value }) => value?.categories?.map(category => category?.name)?.join?.(', ')
    },
    {
        Label: () => 'Price',
        Component: ({ value }) => `${value?.price}$`
    },
    {
        Label: () => 'Created At',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} dateFormat="dd/MM/yyyy" />
    },
    {
        Label: () => 'Actions',
        Component: ActionDropdown
    }
];

export default PlansColumns;

