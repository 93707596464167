const RenderUserStatus = ({ userStatus }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', textTransform: 'capitalize' }}>
            {userStatus?.name}
            <div
                style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '100%',
                    background: userStatus?.color
                }}
            />
        </div>
    )
}

export default RenderUserStatus;