import ActionDropdown from "../../ActionDropdown";
import DateFormatter from "../../DateFormatter";
import ImageAndName from "../../ImageAndName";
import RenderUserStatus from "../../RenderUserStatus";
import TableCheck from "../TableCheck";

const CombosColumns = [
    {
        Label: TableCheck,
        Component: TableCheck
    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Name',
        Component: ({ value }) => `${value?.name}`
    },
    {
        Label: () => 'recipes',
        Component: ({ value }) => `${value?.recipes?.length}`
    },
    {
        Label: () => 'plans',
        Component: ({ value }) => `${value?.plans?.length}`
    },
    {
        Label: () => 'Price',
        Component: ({ value }) => `${value?.price}$`
    },
    {
        Label: () => 'Categories',
        Component: ({ value }) => `${value?.categories?.map(category => category?.name)?.join(', ')}`
    },
    {
        Label: () => 'Created At',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} dateFormat="dd/MM/yyyy" />
    },
    {
        Label: () => 'Actions',
        Component: ActionDropdown
    }
];

export default CombosColumns;

