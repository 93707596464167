const Footer = () => {
    var d = new Date();
    return (
        <div className="footer">
            <div className="copyright">
                <p>
                    © Todos los Derechos Reservados. {" "}
                    <a href="http://rickrecipes.com/" target="_blank" rel="noreferrer">
                        www.rickrecipes.com
                    </a>{" "}
                    {d.getFullYear()}
                </p>
            </div>
        </div>
    );
};

export default Footer;
