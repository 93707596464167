import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImgUploadInput from "../../../components/ImgUploadInput";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import useIngredientsTypes from "../../../hooks/useIngredientsTypes";
import SystemInfo from "../../../util/SystemInfo";

const IngredientsEdit = () => {

    const { id } = useParams();

    const { setLoading, setCustomAlert } = useFeedBack();

    const navigate = useNavigate();

    const [data, setData] = useState({
        name: '',
        ingredientTypeId: '',
        icon: null
    });

    const [iconPreview, setIconPreview] = useState('');

    const [{ data: dataToUpdate, loading: dataToUpdateLoading }, getData] = useAxios({ url: `/ingredients/${id}` }, { useCache: false });

    const [{ ingredientsTypes, total, size, numberOfPages, error, loading }, getIngredientsTypes] = useIngredientsTypes({ options: { useCache: false } });

    const [{ data: updateData, loading: updateLoading }, updateRegistry] = useAxios({ url: `/ingredients/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading?.({
            show: updateLoading,
            message: `Updating`
        });
    }, [updateLoading])

    useEffect(() => {
        setLoading?.({
            show: dataToUpdateLoading,
            message: `Loading`
        });
    }, [dataToUpdateLoading])

    useEffect(() => {
        if (dataToUpdate) {
            const { name, ingredientType, icon } = dataToUpdate;
            setData((oldData) => {
                return {
                    ...oldData,
                    name: name,
                    ingredientTypeId: ingredientType?.id
                }
            });

            if (icon) setIconPreview(`${SystemInfo?.api}${icon}`);
        }
    }, [dataToUpdate])

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: '¡Successful operation!',
                severity: 'success',
                message: `The ingredient was updated successfully.`,
                show: true
            });
            navigate?.(`/ingredients`)
        }
    }, [updateData])

    const handleSubmit = (e) => {
        e.preventDefault?.();

        const formData = new FormData();

        Object.keys(data).forEach((key, i) => {
            if (key !== 'id') {
                if (data[key]) {
                    if (key === 'icon') {
                        formData.append(key, data[key], data[key].name);
                    } else {
                        formData.append(key, data[key]);
                    }
                }
            }
        })

        updateRegistry({ data: formData });
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    return (
        <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-8">
                                <div className="mb-4">
                                    <label>
                                        <h5>
                                            Ingredient Name
                                        </h5>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        name="name"
                                        autoFocus
                                        value={data?.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label>
                                        Ingredient Type
                                    </label>
                                    <select name="ingredientTypeId" onChange={handleChange} value={data?.ingredientTypeId} className="form-control">
                                        {
                                            loading &&
                                            <option>Loading</option>
                                        }
                                        {
                                            ingredientsTypes?.map((type) => {
                                                return (
                                                    <option value={type?.id}>
                                                        {type?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label>
                                    <h5>
                                        Ingredient Image
                                    </h5>
                                </label>
                                <ImgUploadInput
                                    previewImage={iconPreview}
                                    style={{ width: '65%' }}
                                    description="image"
                                    name="icon"
                                    change={handleChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Volver
                            </Link>
                            <button type="submit" className="btn btn-primary mx-2">
                                Crear
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default IngredientsEdit;
