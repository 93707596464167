import { useState } from "react";
import OrdersColumns from "../../../components/CustomTable/Columns/OrdersColumns";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useAuth } from "../../../context/AuthContext";
import useOrders from "../../../hooks/useOrders";

const Orders = () => {

    const { user } = useAuth();

    const [filters, setFilters] = useState({
        page: 1,
        orderBy: 'createdAt,DESC'
    });

    const [{ orders, total, numberOfPages, loading }] = useOrders({ params: { ...filters } });

    const handlePageChange = (page) => {
        if (page < 11 && page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    return <div>
        <CustomTable
            loading={loading}
            title={'Orders'}
            pages={numberOfPages}
            total={total}
            values={orders}
            currentPage={filters.page}
            collumns={OrdersColumns}
            changePage={handlePageChange}
        />
    </div>
}

export default Orders;