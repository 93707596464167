import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import SystemInfo from "../../util/SystemInfo";

const ForgotPassword = () => {

    const [email, setEmail] = useState('');

    const [showResponseMessage, setShowResponseMessage] = useState({ show: false, message: '', severity: '' });

    const [{ error, loading }, sendEmail] = useAxios({ url: `/auth/forgot-password`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (showResponseMessage.show) {
            setTimeout(() => {
                setShowResponseMessage({ show: false, message: '', severity: '' });
            }, [3000])
        }
    }, [showResponseMessage.show])

    const onSubmit = (e) => {
        e.preventDefault();

        sendEmail({
            data: {
                email,
                role: 'ADMIN'
            }
        }).then(() => {
            setShowResponseMessage({ show: true, message: 'We have sent you an email please check your email.', severity: 'success' });
            setEmail('');
        }).catch((e) => {
            setShowResponseMessage({ show: true, message: 'An error has occurred.', severity: 'danger' });
        });
    };

    return (
        <div className="authincation h-100 p-meddle">
            <div className="container" style={{ height: "100vh !important" }}>
                <div style={{ display: 'flex', width: "100%", height: '100%' }}>
                    <div className="authincation-content" style={{ margin: 'auto', width: '50%' }}>
                        <div className="row no-gutters" >
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <div className="text-center mb-3" style={{ position: 'relative' }}>
                                        <Link to="/login" className="btn btn-primary" style={{ position: 'absolute', left: 0, height: 'auto' }}>
                                            <i className="ti-arrow-left" />
                                        </Link>
                                        <Link to="/login">
                                            <img style={{ width: '40%' }} src={SystemInfo?.logo} alt="logo" />
                                        </Link>
                                    </div>
                                    <h4 className="text-center mb-4 ">Did you forget your password?</h4>
                                    <form onSubmit={onSubmit}>
                                        <div className="form-group my-4">
                                            <label className="">
                                                <strong>Email</strong>
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="form-control"
                                                placeholder="Enter your email address."
                                                autoFocus
                                            />
                                        </div>
                                        <div className="text-center">
                                            {
                                                showResponseMessage?.show &&
                                                <h3 className={`text-${showResponseMessage?.severity} mb-4`}>
                                                    {showResponseMessage?.message}
                                                </h3>
                                            }
                                            <button
                                                disabled={loading}
                                                type="submit"
                                                className="btn btn-primary btn-block"
                                            >
                                                {
                                                    loading ?
                                                        'loading'
                                                        :
                                                        'Send'
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;