import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ImgUploadInput from "../../../components/ImgUploadInput";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import useIngredientsTypes from "../../../hooks/useIngredientsTypes";

const IngredientsCreate = () => {
  const { setLoading, setCustomAlert } = useFeedBack();

  const navigate = useNavigate();

  const [data, setData] = useState({
    name: "",
    ingredientTypeId: "",
    icon: null,
  });

  const [{ ingredientsTypes, loading }, getIngredientsTypes] =
    useIngredientsTypes({ options: { useCache: false } });

  const [{ data: createData, loading: createLoading }, createRegistry] =
    useAxios(
      { url: `/ingredients`, method: "POST" },
      { manual: true, useCache: false }
    );

  useEffect(() => {
    setLoading?.({
      show: createLoading,
      message: `Creating`,
    });
  }, [createLoading]);

  useEffect(() => {
    if (createData) {
      setCustomAlert({
        title: "¡Successful operation!",
        severity: "success",
        message: `The ingredient was created successfully.`,
        show: true,
      });
      navigate?.(`/ingredients`);
    }
  }, [createData]);

  const handleSubmit = (e) => {
    e.preventDefault?.();

    const formData = new FormData();

    Object.keys(data).forEach((key, i) => {
      if (key !== "id") {
        if (data[key]) {
          if (key === "icon") {
            formData.append(key, data[key], data[key].name);
          } else {
            formData.append(key, data[key]);
          }
        }
      }
    });

    createRegistry({ data: formData });
  };

  const handleChange = (e) => {
    setData((oldData) => {
      return {
        ...oldData,
        [e.target.name]:
          e.target.type === "file" ? e.target.files[0] : e.target.value,
      };
    });
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="card-body">
        <div className="basic-form">
          <form onSubmit={handleSubmit}>
            <div className="row mb-5">
              <div className="form-group mb-3 col-md-8">
                <div className="mb-4">
                  <label>
                    <h5>Ingredient Name</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    autoFocus
                    value={data?.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label>Ingredient Type</label>
                  <select
                    disabled={loading}
                    onChange={handleChange}
                    name="ingredientTypeId"
                    value={data?.ingredientTypeId}
                    className="form-control"
                  >
                    {loading && <option>Loading</option>}
                    {!loading && <option value="">Select an option</option>}
                    {ingredientsTypes?.map((type) => {
                      return (
                        <option key={type.id} value={type?.id}>
                          {type?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group mb-3 col-md-4">
                <label>
                  <h5>Ingredient Image</h5>
                </label>
                <ImgUploadInput
                  style={{ width: "65%" }}
                  description="image"
                  name="icon"
                  change={handleChange}
                />
              </div>
            </div>
            <div className="mb-3 d-flex justify-content-end">
              <Link
                to={`#`}
                onClick={() => {
                  navigate(-1);
                }}
                className="btn btn-danger mx-2"
              >
                Volver
              </Link>
              <button type="submit" className="btn btn-primary mx-2">
                Crear
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default IngredientsCreate;
