import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RatingsColumns from "../../../components/CustomTable/Columns/RatingsColumns";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useAuth } from "../../../context/AuthContext";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import useRatings from "../../../hooks/useRatings";

const Ratings = () => {

    const { permissions } = useAuth();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        orderBy: 'createdAt,DESC'
    })

    const [selectedValues, setSelectedValues] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const [{ ratings, total, numberOfPages, error: RatingsError, loading }, getRatings] = useRatings({ params: { ...filters } }, { useCache: false });

    const [{ error: deleteError, loading: deleteLoading }, deleteRatings] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        getRatings();
    }, [])

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Eliminating Records'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'An error occurred while deleting.',
                show: true
            });
        }

        if (RatingsError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'An error has occurred while obtaining the products.',
                show: true
            });
        }
    }, [deleteError, RatingsError])

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(Ratings?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleDelete = (value) => {
        deleteRatings({ url: `/Ratings/${value?.id}` }).then((data) => {
            setCustomAlert({
                title: '¡Successful operation!',
                severity: 'success',
                message: 'The record has been successfully deleted.',
                show: true
            });
            getRatings();
        })
    }

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handlePageChange = (page) => {
        if (page < 11 && page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleDeleteSelected = () => {
        deleteRatings({ url: `/ratings/multiple`, data: { ids: selectedValues } }).then((data) => {
            setCustomAlert({
                title: '¡Successful operation!',
                severity: 'success',
                message: 'Records have been successfully removed.',
                show: true
            });
            getRatings();
        });
    }

    return (
        <div>
            <CustomTable
                onDeleteSelected={handleDeleteSelected}
                onSelectValue={handleSelectValue}
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={'Ratings'}
                updatePath={"/Ratings/edit"}
                onDelete={handleDelete}
                selectedValues={selectedValues}
                pages={numberOfPages}
                total={total}
                values={ratings}
                currentPage={filters.page}
                collumns={RatingsColumns}
                changePage={handlePageChange}
            />
        </div>
    )
}
export default Ratings;