import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminsColumns from "../../../components/CustomTable/Columns/AdminsColumns";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAdmins from "../../../hooks/useAdmins";
import useAxios from "../../../hooks/useAxios";

const Admins = () => {

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        orderBy: 'createdAt,DESC'
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const [{ admins, total, numberOfPages, error: adminsError, loading }, getAdmins] = useAdmins({ params: { ...filters } }, { useCache: false });

    const [{ error: deleteError, loading: deleteLoading }, deleteAdmins] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        getAdmins();
    }, [])

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Deleting Admins'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'An error occurred while deleting.',
                show: true
            });
        }

        if (adminsError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'An error occurred while obtaining the admins.',
                show: true
            });
        }
    }, [deleteError, adminsError])

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(admins?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleDelete = (value) => {
        deleteAdmins({ url: `/admins/${value?.id}` }).then((data) => {
            setCustomAlert({
                title: '¡Successful operation!',
                severity: 'success',
                message: 'The admins has been successfully removed.',
                show: true
            });
            getAdmins();
        })
    }

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handlePageChange = (page) => {
        if (page < 11 && page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleDeleteSelected = () => {
        deleteAdmins({ url: `/admins/multiple`, data: { ids: selectedValues } }).then((data) => {
            setCustomAlert({
                title: '¡Successful operation!',
                severity: 'success',
                message: 'Admins have been successfully removed.',
                show: true
            });
            getAdmins();
        });
    }

    return (
        <div>


            <div className="my-4 justify-content-end d-flex">
                {<Link to={"/users-management/admins/create"} className="btn btn-primary">
                    Create Admin
                </Link>}
            </div>
            <CustomTable
                onDeleteSelected={handleDeleteSelected}
                onSelectValue={handleSelectValue}
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={'Admins'}
                updatePath={"/users-management/admins"}
                onDelete={handleDelete}
                selectedValues={selectedValues}
                pages={numberOfPages}
                total={total}
                values={admins}
                currentPage={filters.page}
                collumns={AdminsColumns}
                changePage={handlePageChange}
            />
        </div>
    )
}
export default Admins;