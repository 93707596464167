import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImgUploadInput from "../../../components/ImgUploadInput";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import SystemInfo from "../../../util/SystemInfo";
import { useAuth } from "../../../context/AuthContext";
import useUsersStatuses from "../../../hooks/useUsersStatuses";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import useOccupations from "../../../hooks/useOccupations";
import { Button, Modal } from "react-bootstrap";

const SellersEdit = () => {

    const { user, setAuthInfo } = useAuth();

    const { id } = useParams();

    const { setLoading, setCustomAlert } = useFeedBack();

    const navigate = useNavigate();

    const [showCredential, setShowCredential] = useState(false);

    const [data, setData] = useState({
        email: '',
        name: '',
        userStatusCode: '',
        whatsapp: '',
        instagram: '',
        paypal: '',
        phoneNumber: '',
        facebook: '',
        shortDescription: '',
        description: '',
        banner: null,
        logo: null,
        frontImage: null,
        occupationIds: []
    });

    const [currentOccupations, setCurrentOccupations] = useState([]);

    const [credentialImage, setCredentialImage] = useState('');

    const [password, setPassword] = useState('');

    const [showSavePasswordMessage, setShowSavePasswordMessage] = useState(false);

    const [bannerPreview, setBannerPreview] = useState('');

    const [logoPreview, setLogoPreview] = useState('');

    const [frontImagePreview, setFrontImagePreview] = useState('');

    const [{ occupations, loading: loadingOccupations }, getOccupations] = useOccupations();

    const [{ usersStatuses, size, total, numberOfPages, error, loading }, getUsersStatuses] = useUsersStatuses();

    const [{ data: dataToUpdate, loading: dataToUpdateLoading }, getdataToUpdate] = useAxios({ url: `/sellers/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading }, update] = useAxios({ url: `/sellers/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ data: updatePassworData, loading: updatePasswordLoading }, updatePassword] = useAxios({ url: `/sellers/${id}/password`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (occupations.length > 0) {
            setCurrentOccupations((oldCurrentOccupations) => {
                return [...oldCurrentOccupations, ...occupations];
            });
        }
    }, [occupations])

    useEffect(() => {
        const selectButton = document?.getElementsByClassName?.('actions-btn')[0];
        selectButton?.setAttribute?.('type', 'button');
        setData((oldData) => {
            return {
                ...oldData
            }
        })
    }, [currentOccupations])

    useEffect(() => {
        if (updatePassworData) {
            setPassword('');
            setShowSavePasswordMessage(true);
        }
    }, [updatePassworData])

    useEffect(() => {
        if (showSavePasswordMessage) {
            setTimeout(() => {
                setShowSavePasswordMessage(false);
            }, 4000)
        }
    }, [showSavePasswordMessage])

    useEffect(() => {
        if (dataToUpdate) {
            const {
                role,
                userStatus,
                description,
                facebook,
                shortDescription,
                credential,
                phoneNumber,
                whatsapp,
                address,
                occupations,
                banner,
                logo,
                frontImage,
                ...rest
            } = dataToUpdate;

            console.log(dataToUpdate);

            setData((oldData) => {
                return {
                    ...oldData,
                    ...rest,
                    whatsapp: whatsapp || '',
                    address: address || '',
                    userStatusCode: userStatus?.code,
                    description: description || '',
                    facebook: facebook || '',
                    shortDescription: shortDescription || '',
                    phoneNumber: phoneNumber || '',
                    occupationIds: occupations?.map((occupation) => `${occupation?.id}`)
                }
            });

            if (credential) setCredentialImage(`${SystemInfo.api}${credential}`);
            if (banner) setBannerPreview(`${SystemInfo.api}${banner}`);
            if (logo) setLogoPreview(`${SystemInfo.api}${logo}`);
            if (frontImage) setFrontImagePreview(`${SystemInfo.api}${frontImage}`);
        }
    }, [dataToUpdate]);

    useEffect(() => {
        setLoading?.({
            show: updateLoading,
            message: `Updating`
        });
    }, [updateLoading]);

    useEffect(() => {
        setLoading?.({
            show: dataToUpdateLoading,
            message: `Loading`
        });
    }, [dataToUpdateLoading])

    useEffect(() => {
        if (updateData) {
            setCustomAlert(oldCustomAlert => {
                return {
                    ...oldCustomAlert,
                    severity: 'success',
                    message: `The user was successfully updated.`,
                    show: true
                }
            });
            if (updateData?.id === user?.id) {
                setAuthInfo((oldAuthInfo) => {
                    return {
                        ...oldAuthInfo,
                        user: updateData
                    }
                });
            }
            console.log(updateData);
            navigate('/users-management/sellers');
        }
    }, [updateData]);

    const handleSubmit = (e) => {
        e.preventDefault?.();

        const formData = new FormData();

        const { id, occupationIds, ...rest } = data;

        Object.keys(rest).forEach((key, i) => {
            if (data[key]) {
                if (key === 'logo' || key === 'banner' || key === 'frontImage') {
                    formData.append(key, data[key], data[key].name);
                } else {
                    formData.append(key, data[key]);
                }
            }
        });

        occupationIds.forEach((id, i) => {
            formData.append(`occupationIds[${i}]`, id)
        });

        update({ data: formData });
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    const handleChangePassword = (e) => {
        e?.preventDefault();
        if (updatePasswordLoading) {
            return;
        }
        updatePassword({ data: { password } });
    }

    return (
        <>
            <div className="card" style={{ width: '100%' }}>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-5">
                                <div className="col-md-12 mb-4">
                                    <label>
                                        <h5>
                                            Banner Image
                                        </h5>
                                    </label>
                                    <ImgUploadInput
                                        previewImage={bannerPreview}
                                        description="Banner Image"
                                        name="banner"
                                        change={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-8">
                                    <div className="mb-4">
                                        <label>
                                            <h5>
                                                Name
                                            </h5>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                            name="name"
                                            autoFocus
                                            value={data?.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            name="email"
                                            value={data?.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>
                                            Whatsapp
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="whatsapp..."
                                            name="whatsapp"
                                            value={data?.whatsapp}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>
                                        <h5>
                                            Logo Image
                                        </h5>
                                    </label>
                                    <ImgUploadInput
                                        previewImage={logoPreview}
                                        style={{ width: '65%' }}
                                        description="Logo Image"
                                        name="logo"
                                        change={handleChange}
                                    />
                                </div>
                                <div className="col-md-12 form-group mb-3">
                                    <label>
                                        Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone Number..."
                                        name="phoneNumber"
                                        value={data?.phoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <div className="form-group mb-3">
                                        <label>
                                            Instagram
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="instagram..."
                                            name="instagram"
                                            value={data?.instagram}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            Paypal
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="paypal..."
                                            name="paypal"
                                            value={data?.paypal}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3 form-group">
                                        <label>
                                            Facebook
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="facebook..."
                                            name="facebook"
                                            value={data?.facebook}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>
                                        <h5>
                                            Front Image
                                        </h5>
                                    </label>
                                    <ImgUploadInput
                                        previewImage={frontImagePreview}
                                        description="Front Image"
                                        name="frontImage"
                                        change={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label>
                                            Status
                                        </label>
                                        <select className="form-control" onChange={handleChange} name="userStatusCode" value={data.userStatusCode}>
                                            {
                                                usersStatuses?.map((status, i) => {
                                                    return (
                                                        <option key={i} value={status?.code}>
                                                            {
                                                                status?.name
                                                            }
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label>
                                            Short Description
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="shortDescription..."
                                            name="shortDescription"
                                            value={data?.shortDescription}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="mb-1">
                                            <strong>Occupation</strong>
                                        </label>
                                        <div id="multiselect" className="text-center text-xl">
                                            {
                                                loadingOccupations ?
                                                    <h3>Loading...</h3>
                                                    :
                                                    currentOccupations.length > 0 ?
                                                        <DropdownMultiselect
                                                            options={currentOccupations}
                                                            selected={data?.occupationIds}
                                                            name="occupationIds"
                                                            buttonClass="btn-primary"
                                                            optionKey="id"
                                                            optionLabel="name"
                                                            handleOnChange={(e) => { setData((oldData) => { return { ...oldData, occupationIds: e } }) }} />
                                                        :
                                                        <h3>There are no occupations.</h3>
                                            }
                                        </div>
                                        <br />
                                        <div>
                                            <label htmlFor="">Credential Image</label>
                                            <button type="button" className="btn btn-block btn-primary" onClick={() => { setShowCredential(true) }}>Show Credential</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div>
                                        <label>
                                            Description
                                        </label>
                                        <textarea name="description" onChange={handleChange} value={data?.description} className="form-control" style={{ height: 120 }} rows={8}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 d-flex justify-content-end">
                                <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                    Back
                                </Link>
                                <button type="submit" className="btn btn-primary mx-2">
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="card p-4">
                <h4>
                    New Password
                </h4>
                <form onSubmit={handleChangePassword}>
                    <div className="mb-4">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="new password..."
                            name="password"
                            value={password}
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                    </div>
                    <div className="text-end">
                        {
                            updatePassworData && showSavePasswordMessage ?
                                <span>The password has been changed successfully.</span>
                                :
                                null
                        }
                        <button type="submit" disabled={updatePasswordLoading} className="btn btn-primary mx-2">
                            {
                                updatePasswordLoading ?
                                    'Loading'
                                    :
                                    'Change Password'
                            }
                        </button>
                    </div>
                </form>
            </div>
            <Modal size="lg" className="fade" show={showCredential}>
                <Modal.Header>
                    <Modal.Title>Credential</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setShowCredential(false)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <img style={{ maxWidth: '100%' }} src={credentialImage} />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setShowCredential(false)}
                        variant="danger light"
                    >
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default SellersEdit;