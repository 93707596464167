import ActionDropdown from "../../ActionDropdown";
import DateFormatter from "../../DateFormatter";
import ImageAndName from "../../ImageAndName";
import RenderUserStatus from "../../RenderUserStatus";
import TableCheck from "../TableCheck";

const RecipesColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Name',
        Component: ImageAndName
    },
    {
        Label: () => 'Categories',
        Component: ({ value }) => value?.categories?.map(category => category?.name)?.join?.(', ')
    },
    {
        Label: () => 'Difficulty',
        Component: ({ value }) => value?.recipeDifficulty?.name
    },
    {
        Label: () => 'Premiun  🌟',
        Component: ({ value }) => value?.isPremium ? 'Yes' : 'No'
    },
    {
        Label: () => 'Price',
        Component: ({ value }) => value?.price && value?.isPremium ? `${value?.price}$` : 'Free'
    },
    {
        Label: () => 'Preparation Time  ⏰',
        Component: ({ value }) => value?.preparationTime ? `${value?.preparationTime}min` : '--'
    },
    {
        Label: () => 'Type',
        Component: ({ value }) => value?.mealPeriods?.map?.(period => period?.name)?.join(', ')
    },
    {
        Label: () => 'Created At',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} dateFormat="dd/MM/yyyy" />
    },
    {
        Label: () => 'Actions',
        Component: ActionDropdown
    }
];

export default RecipesColumns;

