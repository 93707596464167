import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImgUploadInput from "../../../components/ImgUploadInput";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";

const AdminsCreate = () => {

    const { setLoading, setCustomAlert } = useFeedBack();

    const navigate = useNavigate();

    const [data, setData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        address: '',
        password: '',
        passwordVerify: '',
        image: null,
        userStatusCode: 'urs-001'
    });

    const [imagePreview, setImagePreview] = useState('');

    const [{ data: createData, loading: createLoading }, create] = useAxios({ url: `/admins`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading?.({
            show: createLoading,
            message: `Creating`
        });
    }, [createLoading]);

    useEffect(() => {
        if (createData) {
            setCustomAlert(oldCustomAlert => {
                return {
                    ...oldCustomAlert,
                    severity: 'success',
                    message: `The user was successfully created.`,
                    show: true
                }
            });
            navigate('/users-management/admins');
        }
    }, [createData]);

    const handleSubmit = (e) => {
        e.preventDefault?.();

        const formData = new FormData();

        const { id, passwordVerify, ...rest } = data;

        if (data.password !== data.passwordVerify) {
            alert('Passwords do not match');
        }

        Object.keys(rest).forEach((key, i) => {
            if (data[key]) {
                if (key === 'image') {
                    formData.append(key, data[key], data[key].name);
                } else {
                    formData.append(key, data[key]);
                }
            }
        });

        create({ data: formData });
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    return (
        <div className="card" style={{ width: '100%', marginBottom: 200 }}>
            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-8">
                                <div className="mb-4">
                                    <label>
                                        <h5>
                                            Name
                                        </h5>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        name="name"
                                        autoFocus
                                        value={data?.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label>
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={data?.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label>
                                        Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="phone number..."
                                        name="phoneNumber"
                                        value={data?.phoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label>
                                    <h5>
                                        Profile Image
                                    </h5>
                                </label>
                                <ImgUploadInput
                                    previewImage={imagePreview}
                                    style={{ width: '65%' }}
                                    description="Profile Image"
                                    name="image"
                                    change={handleChange}
                                />
                            </div>
                            <div className="col-md-12">
                                <div className="mb-4">
                                    <label>
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        name="password"
                                        value={data?.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label>
                                        Password Verify
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        name="passwordVerify"
                                        value={data?.passwordVerify}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <label>
                                        Address
                                    </label>
                                    <textarea name="address" onChange={handleChange} value={data?.address} className="form-control" style={{ height: 120 }} rows={8}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Back
                            </Link>
                            <button type="submit" className="btn btn-primary mx-2">
                                Create
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default AdminsCreate;