import Admins from "../pages/private/admins/Admins";
import AdminsCreate from "../pages/private/admins/AdminsCreate";
import AdminsEdit from "../pages/private/admins/AdminsEdit";
import Categories from "../pages/private/categories/Categories";
import CategoriesCreate from "../pages/private/categories/CategoriesCreate";
import CategoriesEdit from "../pages/private/categories/CategoriesEdit";
import Customers from "../pages/private/customers/Customers";
import CustomersEdit from "../pages/private/customers/CustomersEdit";
import Dashboard from "../pages/private/Dashboard";
import Ingredients from "../pages/private/ingredients/Ingredients";
import IngredientsCreate from "../pages/private/ingredients/IngredientsCreate";
import IngredientsEdit from "../pages/private/ingredients/IngredientsEdit";
import Sellers from "../pages/private/sellers/Sellers";
import SellersEdit from "../pages/private/sellers/SellersEdit";
import Orders from "../pages/private/orders/Orders";
import OrdersDetails from "../pages/private/orders/OrdersDetails";
import Ratings from "../pages/private/ratings/Ratings";
import Plans from "../pages/private/plans/Plans";
import Recipes from "../pages/private/recipes/Recipes";
import Combos from "../pages/private/combos/Combos";

const createLink = (
    title,
    hidden,
    component,
    icon,
    path,
    permissions,
    children
) => ({ title, hidden, component, icon, path, permissions, children });

export const mainPermissions = {
    dashboard: ['sys-001-view-dashboard'],
    permissions: ['sys-001-view-permissions'],
    positions: ['sys-001-view-positions', 'sys-001-create-positions', 'sys-001-update-positions', 'sys-001-delete-positions'],
    services: ['sys-001-view-services', 'sys-001-create-services', 'sys-001-update-services', 'sys-001-delete-services'],
    users: ['sys-001-view-users', 'sys-001-create-users', 'sys-001-update-users', 'sys-001-delete-users'],
    roles: ['sys-001-view-roles', 'sys-001-create-roles', 'sys-001-update-roles', 'sys-001-delete-roles'],
    providers: ['sys-001-view-users', 'sys-001-create-users', 'sys-001-update-users', 'sys-001-delete-users'],
    products: ['sys-001-view-users', 'sys-001-create-users', 'sys-001-update-users', 'sys-001-delete-users', 'sys-001-view-users', 'sys-001-create-users'],
    categories: ['sys-001-view-categories', 'sys-001-create-categories', 'sys-001-update-categories', 'sys-001-delete-categories'],
    features: ['sys-001-view-product-features', 'sys-001-create-product-features', 'sys-001-update-product-features', 'sys-001-delete-product-features'],
    productFeaturesOptions: ['sys-001-view-product-feature-options', 'sys-001-create-product-feature-options', 'sys-001-update-product-feature-options', 'sys-001-delete-product-feature-options']
}

const MenuLinks = [
    createLink('DashBoard', null, <Dashboard />, 'flaticon-381-dashboard', '/dashboard', null),

    createLink('Users', null, null, 'flaticon-381-user-9', '/users-management', null, [
        createLink('Customers', null, <Customers />, '', '/users-management/customers', null),
        createLink('Customers Edit', true, <CustomersEdit />, '', '/users-management/customers/:id'),
        createLink('Sellers', null, <Sellers />, '', '/users-management/sellers', null),
        createLink('Sellers Edit', true, <SellersEdit />, '', '/users-management/sellers/:id'),
        createLink('Admins', null, <Admins />, '', '/users-management/admins', null),
        createLink('Admins Create', true, <AdminsCreate />, '', '/users-management/admins/create', null),
        createLink('Admins Edit', true, <AdminsEdit />, '', '/users-management/admins/:id', null),
    ]),

    createLink('Orders', null, <Orders />, 'flaticon-381-list', '/orders', null),
    createLink('Orders Details', true, <OrdersDetails />, 'flaticon-381-list', '/orders/:id', null),

    createLink('Recipes', null, null, 'flaticon-381-list', '/recipes', null, [

        createLink('Recipes', null, <Recipes />, '', '/recipes/recipes', null),

        createLink('Plans', null, <Plans />, '', '/recipes/plans', null),

        createLink('Combos', null, <Combos />, '', '/recipes/combos', null),

        createLink('Categories', null, <Categories />, '', '/recipes/categories', null),
        createLink('Categories Create', true, <CategoriesCreate />, '', '/recipes/categories/create', null),
        createLink('Categories Update', true, <CategoriesEdit />, '', '/recipes/categories/:id', null),
    ]),

    createLink('Ratings', null, <Ratings />, 'flaticon-381-list', '/ratings', null),

    createLink('Ingredients', null, <Ingredients />, 'flaticon-381-notepad-2', '/ingredients', null, [
        createLink('List', null, <Ingredients />, 'flaticon-381-list', '/ingredients', null),
        createLink('Create', null, <IngredientsCreate />, 'flaticon-381-list', '/ingredients/create', null),
        createLink('Edit Ingredient', true, <IngredientsEdit />, 'flaticon-381-list', '/ingredients/edit/:id', null)
    ]),

    /* createLink('Caracteristicas', null, null, 'flaticon-381-tab', '/caracteristicas', null, [
        createLink('Crear Caracteristica', null, <FeaturesCreate />, '', '/caracteristicas/crear'),
        createLink('Listar Caracteristicas', null, <Features />, '', '/caracteristicas'),
        createLink('Actualizar Caracteristicas', true, <FeaturesUpdate />, '', '/caracteristicas/:id'),
    ]), */


];

export default MenuLinks;