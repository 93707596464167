import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import SystemInfo from "../../util/SystemInfo";

const ResetPassword = () => {

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [data, setData] = useState({
        password: "",
        email: "",
        token: "",
        passwordConfirm: ""
    });

    const [showResponseMessage, setShowResponseMessage] = useState(false);

    const [{ loading }, sendResetPassword] = useAxios({ url: `/auth/reset-password`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (showResponseMessage.show) {
            setTimeout(() => {
                setShowResponseMessage({ show: false, message: '', severity: '' });
            }, [3000])
        }
    }, [showResponseMessage.show])

    useEffect(() => {
        const email = searchParams?.get('email');

        const token = searchParams?.get('token');

        if (email && token) {
            setData((oldData) => {
                return {
                    ...oldData,
                    email: email,
                    token: token
                }
            });
        } else {
            navigate('/');
        }
    }, [searchParams]);

    const onSubmit = (e) => {
        e.preventDefault();

        if (loading) return;

        if (data?.password !== data?.passwordConfirm) {
            alert('Las contraseñas no coinciden');
            return;
        }

        const { passwordConfirm, ...rest } = data;

        sendResetPassword({ data: rest }).then(() => {
            setShowResponseMessage(true);
        });

    }

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        <div className="authincation h-100 p-meddle">
            <div className="container" style={{ height: "100vh !important" }}>
                <div style={{ display: 'flex', width: "100%", height: '100%' }}>
                    <div className="authincation-content" style={{ margin: 'auto', width: '50%' }}>
                        <div className="row no-gutters" >
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <div className="text-center mb-3" style={{ position: 'relative' }}>
                                        <Link to="/login" className="btn btn-primary" style={{ position: 'absolute', left: 0, height: 'auto' }}>
                                            <i className="ti-arrow-left" />
                                        </Link>
                                        <Link to="/login">
                                            <img style={{ width: '40%' }} src={SystemInfo?.logo} alt="logo" />
                                        </Link>
                                    </div>
                                    {
                                        showResponseMessage ?
                                            <div className="text-center">
                                                <h3>
                                                    Congratulations your password has been successfully updated
                                                </h3>
                                                <p>
                                                    If you want you can go back to the login and try to start session again.
                                                </p>
                                                <Link to={'/login'} className="btn btn-primary btn-block">
                                                    Go to login page
                                                </Link>
                                            </div>
                                            :
                                            <div>
                                                <h4 className="text-center mb-4 ">Enter your new password</h4>
                                                <form onSubmit={onSubmit}>
                                                    <div className="form-group my-4">
                                                        <label className="">
                                                            <strong>New Password</strong>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            value={data?.password}
                                                            name="password"
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Enter your new password."
                                                            autoFocus
                                                        />
                                                    </div>
                                                    <div className="form-group my-4">
                                                        <label className="">
                                                            <strong>Confirm Password</strong>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            value={data?.passwordConfirm}
                                                            name="passwordConfirm"
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Confirm your new password."
                                                        />
                                                    </div>
                                                    <div className="text-center">
                                                        {
                                                            showResponseMessage?.show &&
                                                            <h3 className={`text-${showResponseMessage?.severity} mb-4`}>
                                                                {showResponseMessage?.message}
                                                            </h3>
                                                        }
                                                        <button
                                                            disabled={loading}
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                        >
                                                            {
                                                                loading ?
                                                                    'loading'
                                                                    :
                                                                    'Send'
                                                            }
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;