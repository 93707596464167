import { useEffect, useState } from "react";
import SystemInfo from "../util/SystemInfo";

const ImageAndName = ({ nameValue, imgValue, value }) => {

    const [imageValue, setImageValue] = useState('');

    useEffect(() => {
        if (imgValue) setImageValue(`${SystemInfo?.api}${imgValue}`);
        if (value?.logo) setImageValue(`${SystemInfo?.api}${value?.logo}`);
        if (value?.appLogo) setImageValue(`${SystemInfo?.api}${value?.appLogo}`);
        if (value?.icon) setImageValue(`${SystemInfo?.api}${value?.icon}`);
    }, [value, imgValue]);

    return (
        <div className="text-center d-flex align-items-center">
            {
                imageValue &&
                <img
                    src={`${imageValue}`}
                    className="rounded-lg"
                    width="36"
                    height={"36"}
                    alt="image"
                />
            }
            <span className="w-space-no mx-1">{nameValue ? nameValue : ''}</span>
        </div>
    )
}

export default ImageAndName;