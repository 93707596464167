import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomSelect from "../../../components/CustomSelect";
import ImgUploadInput from "../../../components/ImgUploadInput";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import useCategories from "../../../hooks/useCategories";
import SystemInfo from "../../../util/SystemInfo";

const CategoriesEdit = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { setLoading, setCustomAlert } = useFeedBack();

    const [filters, setFilters] = useState({
        perPage: 200,
        page: 1,
        name: ''
    });

    const [logoPreview, setLogoPreview] = useState('');

    const [bannerPreview, setBannerPreview] = useState('');

    const [data, setData] = useState({
        name: '',
        parentId: '',
        banner: null,
        logo: null
    });

    const [{ data: dataToUpdate, loading: dataToUpdateLoading }, getdataToUpdate] = useAxios({ url: `/categories/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading, error: updateError }, updateCategory] = useAxios({ url: `/categories/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ categories, error: categoriesError, loading: categoriesLoading }, getCategories] = useCategories({ axiosConfig: { params: { ...filters } }, options: { useCache: false } });

    useEffect(() => {
        if (dataToUpdate) {

            const { banner, appLogo } = dataToUpdate;

            setData((oldData) => {
                return {
                    ...oldData,
                    name: dataToUpdate?.name,
                    parentId: dataToUpdate?.parentCategory?.id || ''
                }
            });

            if (banner) setBannerPreview(`${SystemInfo.api}${banner}`);
            if (appLogo) setLogoPreview(`${SystemInfo.api}${appLogo}`);


        }
    }, [dataToUpdate]);

    useEffect(() => {
        setLoading?.({
            show: dataToUpdateLoading,
            message: `Loading`
        });
    }, [dataToUpdateLoading])

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: 'Successful operation!',
                severity: 'success',
                message: 'The category was updated successfully.',
                show: true
            });
            navigate('/recipes/categories');
        }
    }, [updateData])

    useEffect(() => {
        if (updateError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'An error has occurred.',
                show: true
            });
        }

        if (categoriesError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'An error has occurred when obtaining the categories.',
                show: true
            });
        }
    }, [updateError, categoriesError])

    const handleSubmit = (e) => {
        e?.preventDefault?.();

        if (updateLoading) {
            return;
        }


        if (!data?.name) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Name is required.',
                show: true
            });
            return;
        }

        const formData = new FormData();

        Object.keys(data).forEach((key, i) => {
            if (data[key]) {
                if (key === 'logo' || key === 'banner') {
                    formData.append(key, data[key], data[key].name);
                } else {
                    formData.append(key, data[key]);
                }
            }
        });

        updateCategory({ data: formData });
    }

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    const handleCategory = (category) => {
        setData((oldData) => {
            return {
                ...oldData,
                parentId: category?.id
            }
        });
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                name: category?.name
            }
        });
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Update Category</h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-5">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="name..."
                                        name="name"
                                        value={data?.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Parent category (Optional)</label>
                                    <CustomSelect
                                        options={categories}
                                        optionLabel="name"
                                        inputPlaceholder="Write the name..."
                                        isLoading={categoriesLoading}
                                        onSelectValue={handleCategory}
                                        handleInputChange={(e) => { setFilters((oldFilters) => { return { ...oldFilters, name: e.target.value } }) }}
                                        inputValue={filters?.name}
                                    />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="form-group mb-3 col-md-6">
                                    <label>
                                        <h5>
                                            Category Icon
                                        </h5>
                                    </label>
                                    <ImgUploadInput
                                        previewImage={logoPreview}
                                        style={{ width: '80px', height: '80px' }}
                                        description="icon"
                                        name="logo"
                                        change={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>
                                        <h5>
                                            Category Image
                                        </h5>
                                    </label>
                                    <ImgUploadInput
                                        previewImage={bannerPreview}
                                        style={{ width: '65%' }}
                                        description="Category Image"
                                        name="banner"
                                        change={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex justify-content-end">
                                <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                    Cancel
                                </Link>
                                <button disabled={updateLoading} type="submit" className="btn btn-primary mx-2">
                                    {
                                        updateLoading ?
                                            'Loading'
                                            :
                                            'Create'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CategoriesEdit;