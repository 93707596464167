import ActionDropdown from "../../ActionDropdown";
import ImageAndName from "../../ImageAndName";
import TableCheck from "../TableCheck";
import { format } from "date-fns";
import DateFormatter from "../../DateFormatter";

const IngredientsColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Name',
        Component: ImageAndName
    },
    {
        Label: () => 'Type',
        Component: ({ value }) => value?.ingredientType?.name || ''
    },
    {
        Label: () => 'Created At',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} dateFormat="dd/MM/yyyy" />
    },
    {
        Label: () => 'Actions',
        Component: ActionDropdown
    }
];

export default IngredientsColumns;

