import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImgUploadInput from "../../../components/ImgUploadInput";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import SystemInfo from "../../../util/SystemInfo";
import useUsersStatuses from "../../../hooks/useUsersStatuses";

const CustomersEdit = () => {

    const { id } = useParams();

    const { setLoading, setCustomAlert } = useFeedBack();

    const navigate = useNavigate();

    const [data, setData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        image: null,
        userStatusCode: ''
    });

    const [password, setPassword] = useState('');

    const [showSavePasswordMessage, setShowSavePasswordMessage] = useState(false);

    const [imagePreview, setImagePreview] = useState('');

    const [{ data: dataToUpdate, loading: dataToUpdateLoading }, getdataToUpdate] = useAxios({ url: `/clients/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading }, update] = useAxios({ url: `/clients/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ data: updatePassworData, loading: updatePasswordLoading }, updatePassword] = useAxios({ url: `/clients/${id}/password`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ usersStatuses, size, total, numberOfPages, error, loading }, getUsersStatuses] = useUsersStatuses();

    useEffect(() => {
        if (updatePassworData) {
            setPassword('');
            setShowSavePasswordMessage(true);
        }
    }, [updatePassworData])

    useEffect(() => {
        if (showSavePasswordMessage) {
            setTimeout(() => {
                setShowSavePasswordMessage(false);
            }, 4000)
        }
    }, [showSavePasswordMessage])

    useEffect(() => {
        if (dataToUpdate) {
            const { imgPath, role, userStatus, phoneNumber, address, ...rest } = dataToUpdate;

            setData((oldData) => {
                return {
                    ...oldData,
                    ...rest,
                    phoneNumber: phoneNumber || '',
                    userStatusCode: userStatus?.code || ''
                }
            });

            if (imgPath) {
                setImagePreview(`${SystemInfo.api}${imgPath}`);
            }
        }
    }, [dataToUpdate]);

    useEffect(() => {
        setLoading?.({
            show: updateLoading,
            message: `Updating`
        });
    }, [updateLoading]);

    useEffect(() => {
        setLoading?.({
            show: dataToUpdateLoading,
            message: `Loading`
        });
    }, [dataToUpdateLoading])

    useEffect(() => {
        if (updateData) {
            setCustomAlert(oldCustomAlert => {
                return {
                    ...oldCustomAlert,
                    severity: 'success',
                    message: `The user was successfully updated.`,
                    show: true
                }
            });
            navigate('/users-management/customers');
        }
    }, [updateData]);

    const handleSubmit = (e) => {
        e.preventDefault?.();

        const formData = new FormData();

        const { id, ...rest } = data;

        Object.keys(rest).forEach((key, i) => {
            if (data[key]) {
                if (key === 'image') {
                    formData.append(key, data[key], data[key].name);
                } else {
                    formData.append(key, data[key]);
                }
            }
        });

        update({ data: formData });
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    const handleChangePassword = (e) => {
        e?.preventDefault();

        if (updatePasswordLoading) {
            return;
        }
        updatePassword({ data: { password } });
    }

    return (
        <>
            <div className="card" style={{ width: '100%' }}>
                <div className="card-body">
                    <div className="basic-form">
                        <div className="col-md-12 form-group mb-3">
                            <label><h5>Estatus</h5></label>
                            <select
                                className="form-control text-capitalize"
                                value={data?.userStatusCode}
                                name="userStatusCode"
                                onChange={handleChange}
                            >
                                {
                                    usersStatuses?.map((status, i) => {
                                        return (
                                            <option
                                                key={i}
                                                value={status?.code}
                                                className="text-capitalize"
                                            >
                                                {status?.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-5">
                                <div className="form-group mb-3 col-md-8">
                                    <div className="mb-4">
                                        <label>
                                            <h5>
                                                Name
                                            </h5>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                            name="name"
                                            autoFocus
                                            value={data?.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            name="email"
                                            value={data?.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>
                                            Phone Number
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="phone number..."
                                            name="phoneNumber"
                                            value={data?.phoneNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>
                                        <h5>
                                            Profile Image
                                        </h5>
                                    </label>
                                    <ImgUploadInput
                                        previewImage={imagePreview}
                                        style={{ width: '65%' }}
                                        description="Profile Image"
                                        name="image"
                                        change={handleChange}
                                    />
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label>
                                        Estado
                                    </label>
                                    <select className="form-control" onChange={handleChange} name="userStatusCode" value={data.userStatusCode}>
                                        {
                                            usersStatuses?.map((status, i) => {
                                                return (
                                                    <option key={i} value={status?.code}>
                                                        {
                                                            status?.name
                                                        }
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 d-flex justify-content-end">
                                <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                    Back
                                </Link>
                                <button type="submit" className="btn btn-primary mx-2">
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="card p-4">
                <h4>
                    New Password
                </h4>
                <form onSubmit={handleChangePassword}>
                    <div className="mb-4">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="new password..."
                            name="password"
                            value={password}
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                    </div>
                    <div className="text-end">
                        {
                            updatePassworData && showSavePasswordMessage ?
                                <span>The password has been changed successfully.</span>
                                :
                                null
                        }
                        <button type="submit" disabled={updatePasswordLoading} className="btn btn-primary mx-2">
                            {
                                updatePasswordLoading ?
                                    'Loading'
                                    :
                                    'Change Password'
                            }
                        </button>
                    </div>
                </form>
            </div>

        </>
    )
}
export default CustomersEdit;